
import { arrayOf, shape } from 'vue-types';
import { isNullableNumber } from '~/types/isNullable';

const GRID_COMPONENTS = {
  gridHeadingItem: 'GridHeading',
  gridTextItem: 'GridBody',
  gridVisualItem: 'GridVisual',
  productCard: 'ProductCard',
  storyCard: 'BaseCard',
  recipeCard: 'BaseCard',
};

export default {
  inheritAttrs: false,
  props: {
    columns: arrayOf(
      shape({
        column1Start: isNullableNumber().def(1),
        column1Span: isNullableNumber().def(6),
        column2Start: isNullableNumber().def(7),
        column2Span: isNullableNumber().def(6),
      }).loose,
    ),
    items: arrayOf(Object).def([]),
  },
  methods: {
    getComponent({ type }) {
      return GRID_COMPONENTS[type];
    },
    getComponentProps(item) {
      return (
        {
          gridHeadingItem: item,
          gridTextItem: item,
          gridVisualItem: item,
          productCard: this.$first(item.product),
          storyCard: this.$first(item.story),
          recipeCard: this.$first(item.recipe),
        }[item.type] || {}
      );
    },
    getColClasses(i) {
      const { column1Start, column1Span, column2Start, column2Span } = this.$first(this.columns) || {};
      return [
        `l:col-start-${column1Start} l:col-span-${column1Span}`,
        `l:col-start-${column2Start} l:col-span-${column2Span}`,
      ][i];
    },
  },
};
